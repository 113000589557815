<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3 desktop-view">
          <span>
            <span class="text-primary desktop-view h6">
              <!-- Job {{ actionDetail.job_id }} : -->
              Job {{ actionDetail.job_display_uid }} :
              {{ actionDetail.job.job_title }}
            </span>
            <span class="text-primary d-md-block d-none desktop-view budget-class">
              <!-- Candidate {{ actionDetail.candidate_uid }} : -->
              Candidate {{ actionDetail.candidate_display_uid }} :
              {{ actionDetail.candidate.full_name }}
            </span>
            <div v-if="showJobInfo">
              <span class="text-primary d-md-block d-none desktop-view budget-class">
              <!-- Candidate {{ actionDetail.candidate_uid }} : -->
              Total Budget : {{ actionDetail?.job?.allocated_budget || "--" }}
              {{ total_budget_currency }}
            </span>
            <span class="text-primary d-md-block d-none desktop-view budget-class">
              Positions available : {{ actionDetail?.job?.positions_available }}
            </span>
            </div>
           
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary desktop-view h6">
              <span>Created : {{ actionDetail.created_on | formatDate }}</span>
              <span class="d-block"
                ><span class="pr-1">Days Left : </span
                >{{ actionDetail.display_text_days }}
              </span>
            </span>
          </div>
        </div>

        <div class="pb-3 mobile-view">
          <span>
            <span class="text-primary mobile-view h6">
              <!-- Job {{ actionDetail.job_id }} : -->
              Job {{ actionDetail.job_display_uid }} :
              {{ actionDetail.job.job_title }}
            </span>
            <span class="text-primary mobile-view h6 d-block">
              <!-- Candidate {{ actionDetail.candidate_uid }} : -->
              Candidate {{ actionDetail.candidate_display_uid }} :
              {{ actionDetail.candidate.full_name }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="text-primary font-weight-bold mobile-view">
              <span>Created :{{ actionDetail.created_on | formatDate }}</span>
              <span class="d-block"
                ><span class="font-weight-lighter pr-1 mobile-view"
                  >Days Left:</span
                >{{ actionDetail.display_text_days }}
              </span>
            </span>
          </div>
        </div>
        <div>
          <div class="pt-2">
            <CRow>
              <CCol md="7">
                <div class="pb-2">
                  <CTabs
                    variant="tabs"
                    :active-tab="0"
                    @update:activeTab="changeActiveTab"
                  >
                    <CTab title="Candidate">
                      <div class="mt-1 mb-2">
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="showDocuments = !showDocuments"
                          >View Documents</CButton
                        >
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="
                            navToCandidateDetails(actionDetail.candidate_uid)
                          "
                          >View Profile</CButton
                        >
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="openFrontSheet"
                        >
                          Frontsheet
                          <i class="fas fa-download ml-2"></i>
                        </CButton>
                      </div>
                    </CTab>
                    <CTab title="Job">
                      <div class="mt-1 mb-2">
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="openJobAdvert(actionDetail.job_id)"
                          >Preview Advert</CButton
                        >
                        <CButton
                          type="button"
                          color="primary"
                          class="mx-1 py-1 mt-1"
                          @click="navToJobDetails(actionDetail.job_id)"
                          >Job Details</CButton
                        >
                      </div>
                    </CTab>
                  </CTabs>
                </div>
                <div
                  v-if="showDocuments && activeTab === 0"
                  class="d-flex align-items-center documentScrolling document-table border mb-2"
                >
                  <table class="w-100 table">
                    <thead>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Download</th>
                      <th>Preview</th>
                    </thead>
                    <tbody>
                      <tr v-for="(document, index) in documents" :key="index">
                        <td>{{ document.lable }}</td>
                        <td>{{ document.name }}</td>
                        <td>
                          <CButton
                            type="button"
                            @click="fileDownload(document)"
                          >
                            <i class="fas fa-download"></i>
                          </CButton>
                        </td>
                        <td>
                          <CButton type="button" @click="preview(document)">
                            <i class="fas fa-eye"></i>
                          </CButton>
                        </td>
                      </tr>
                      <tr v-if="documents.length === 0">
                        <td colspan="4">
                          <h6 class="text-center text-muted">
                            No Documents to Preview
                          </h6>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mx-3">
                  <CRow class="mb-2 mt-2">
                    <CCol xs="2"><h6>Type</h6> </CCol>
                    <CCol xs="10"
                      ><span class="h6 mx-1">:</span
                      >{{ actionDetail.type_of_process | convert2Sentence }}
                    </CCol>
                  </CRow>
                  <CRow class="mb-2">
                    <CCol xs="2"><h6>Style</h6> </CCol>
                    <CCol xs="10"
                      ><span class="h6 mx-1">:</span
                      ><span v-html="interviewMode"></span>
                      {{ actionDetail.interview_mode | convert2Sentence }}
                    </CCol>
                  </CRow>
                  <CRow class="mb-2">
                    <CCol xs="2"><h6>Time</h6> </CCol>
                    <CCol xs="10"
                      ><span class="h6 mx-1">:</span
                      >{{ actionDetail.alloted_time | convert2Sentence }}
                    </CCol>
                  </CRow>
                </div>
                <div>
                  <CDataTable
                    :striped="true"
                    :bordered="true"
                    :small="true"
                    :items="activityComments"
                    :fields="fields"
                    pagination
                    :items-per-page="5"
                    class="p-2"
                  >
                  </CDataTable>
                </div>
              </CCol>
              <CCol md="5">
                <div class="align-middle">
                  <CCard>
                    <CCardBody>
                      <div>
                        <h6 class="text-primary text-center">
                          Interview Confirmed
                        </h6>
                        <h5
                          v-if="actionDetail.selected_slot_date"
                          class="text-center"
                        >
                          {{ actionDetail.selected_slot_date | formatDate }}
                          {{ actionDetail.selected_slot_start | formatTime }}
                        </h5>
                        <div class="d-flex justify-content-center">
                          <CButton
                            color="primary"
                            @click="openMeet"
                            v-if="actionDetail.interview_mode === 'video'"
                            >Join</CButton
                          >
                          <h6 class="text-primary text-center" v-else>
                            <i class="fas fa-phone-alt mr-1"></i
                            >{{ actionDetail.contact_phone | phone }}
                          </h6>
                        </div>
                      </div>
                    </CCardBody>
                  </CCard>
                </div>
                <div class="d-flex justify-content-center mb-2">
                  <CButton color="primary" @click="checkIsExpired"
                    >Create/Update Interview Evaluation</CButton
                  >
                </div>
                <div class="pb-2">
                  <div>
                    <ValidationObserver
                      ref="interviewForm"
                      v-slot="{ handleSubmit }"
                    >
                      <form
                        @submit.prevent="handleSubmit(onSubmit)"
                        name="interviewForm"
                      >
                        <CRow class="pb-3">
                          <label
                            name="action_label"
                            class="required col-lg-12 col-md-12"
                            >Select Action</label
                          >
                          <div class="col-lg-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="action"
                                :value="interview.action"
                                @input="handleChangeSelect"
                                :options="
                                  options && options['actions']
                                    ? options['actions']
                                    : []
                                "
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                        <CRow class="mb-3">
                          <label class="col-lg-12 required">Comment</label>
                          <div class="col-lg-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <TextareaInput
                                name="comments"
                                :value="interview.comments"
                                label="comments"
                                @input="handleInput"
                                :error="errors[0]"
                              ></TextareaInput>
                            </ValidationProvider>
                          </div>
                        </CRow>
                        <div class="text-center mx-0 mt-2 pt-4 pb-4">
                          <CButton
                            type="button"
                            color="primary"
                            class="action-btn mx-2"
                            variant="outline"
                            @click="navToActivityList"
                            >Exit</CButton
                          >
                          <CButton
                            type="button"
                            color="primary"
                            class="action-btn mx-2"
                            @click="onSubmit"
                            >Submit</CButton
                          >
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
      </CCardBody>
    </CCard>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <JobCardPreviewModal
      :isShowPopup="advertModal.isShowPopup"
      :onCancelPreview="advertModalCallBack"
    />
    <FrontSheetModal
      v-if="frontSheetModal.isShowPopup"
      :isShowPopup="frontSheetModal.isShowPopup"
      @modalCallBack="frontSheetModalCallBack"
      :candidateDetail="frontSheetModal.candidate"
      :jobDetail="frontSheetModal.job"
      :frontSheetInfo="frontSheetModal.frontSheetInfo"
      :frontSheets="frontSheetModal.comments"
    />
    <Modal
      v-if="notificationModal.isShowPopup"
      :modalTitle="notificationModal.modalTitle"
      :modalColor="notificationModal.modalColor"
      :modalContent="notificationModal.modalContent"
      :isShowPopup="notificationModal.isShowPopup"
      :buttons="notificationModal.buttons"
      :modalCallBack="notificationModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { getScope, Role, appendAccessToken, generateFullUrlWithToken } from "@/helpers/helper";
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import Select from "@/components/reusable/Fields/Select";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import pdf from "vue-pdf";
import PreviewModal from "@/components/reusable/PreviewModal";
import { BASE_URL } from "@/service_urls";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
import _ from "lodash";
import { getFilterQueryStringWithoutArray } from "@/helpers/helper";
import FrontSheetModal from "@/containers/Frontsheet/FrontSheetModal";
import Modal from "@/components/reusable/Modal";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    actionDetail: {
      type: Object,
      default: () => null,
    },
    comments: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TextareaInput,
    DatePicker,
    TimePicker,
    PreviewModal,
    JobCardPreviewModal,
    Select,
    FrontSheetModal,
    Modal,
  },
  data() {
    return {
      activeTab: 0,
      advertModal: {
        isShowPopup: false,
      },
      showDocuments: false,
      showJobInfo: false,
      interview: {},
      payload: {},
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      slot: {
        slot1: true,
        slot2: false,
        slot3: false,
      },
      fields: [
        { key: "date", _style: "width:25%" },
        {
          key: "name",
          _style: "width:25%",
        },
        {
          key: "role",
          _style: "width:10%",
        },
        {
          key: "status",
          _style: "width:15%",
        },
        {
          key: "comment",
          _style: "width:25%",
        },
      ],
      frontSheetModal: {
        isShowPopup: false,
        candidate: null,
        job: null,
        frontSheetInfo: null,
        comments: [],
      },
      notificationModal: {
        modalColor: "danger",
        modalTitle: "Confirmation",
        modalContent:
          "You are submitting the Interview Evaluation Form prior to the closure of the interview. Do you still want to proceed?",
        buttons: ["No", "Yes"],
        isShowPopup: false,
      },
      generateFullUrlWithToken
    };
  },
  computed: {
    ...mapGetters(["getUploadedDocument", "getCustomerId", "candidateProfile", "getCurrencyForSelectedID","customerUserRoles"]),
    activityComments() {
      return (
        this.comments
          .filter(
            (e) =>
              [54].includes(e.status_id) &&
              [51, 55, 57, 47, null].includes(e.sub_status_id)
          )
          .map((v) => ({
            name: v.initiated_by_user_name || "--",
            date: this.formatDate(v.created_on) || "--",
            comment: v?.comments || "--",
            role:
              (v?.initiated_by_user?.roles &&
                v?.initiated_by_user?.roles
                  .map((v) => _.startCase(v.role_type))
                  .join()) ||
              "--",
            status:
              `${v?.status} ${v?.sub_status ? " - " + v?.sub_status : ""}` ||
              "--",
          })) || []
      );
    },
    options() {
      return {
        actions: [
          { label: "Approved" },
          { label: "On-Hold" },
          { label: "Disapproved" },
          { label: "Reschedule Interview" },
        ],
      };
    },
    interviewMode() {
      let icon = "";
      switch (this.actionDetail.interview_mode) {
        case "call":
          icon = '<i class="fas fa-phone-alt mr-1"></i>';
          break;
        case "video":
          icon = '<i class="fas fa-video mr-1 text-primary"></i>';
          break;
        case "in_person":
          icon = '<i class="fas fa-user-alt mr-1 text-success"></i>';
          break;
        default:
          icon = "";
      }
      return icon;
    },
    isExpired() {
      if (this.actionDetail?.selected_slot_date) {
        let today = new Date();

        let end_date = new Date(this.actionDetail?.selected_slot_date);
        const end_date_split = this.actionDetail?.selected_slot_end.split(":");
        end_date_split
          ? end_date.setHours(
              end_date_split[0],
              end_date_split[1],
              end_date_split[2]
            )
          : null;
        return today > end_date;
      }
      return false;
    },
    interview_link() {
      return this.actionDetail &&
        this.actionDetail?.interview_link &&
        this.actionDetail?.interview_link.indexOf("://") === -1
        ? "http://" + this.actionDetail?.interview_link
        : this.actionDetail?.interview_link;
    },
    documents() {
      return (
        (this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument.map(
            ({
              document_type_id,
              uuid_link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_id,
              comments,
            }) => {
              return {
                link: uuid_link_to_doc,
                lable: document_type.comments,
                name: document_name,
                updatedDate: m(created_on).format("DD-MM-YYYY"),
                document_ext: document_ext?.document_ext,
                document_id: candidate_document_id,
                comments: comments,
                document_type_id: document_type_id,
              };
            }
          )) ||
        []
      );
    },
    isRequired() {
      return this.interview && this.interview.action?.label
        ? ["On-Hold", "Reschedule Interview"].includes(
            this.interview?.action?.label
          )
        : false;
    },
     total_budget_currency() {
      return this.getCurrencyForSelectedID(      
        this.actionDetail?.job?.allocated_budget_currency_id
      ).map((val) => val.label)[0];
    },
  },
  methods: {
    ...mapActions([
      "setCandidateListBackUrl",
      "fetchDocumentByCandidateID",
      "showToast",
      "fetchActivity",
      "downloadDocument",
      "fetchJobBenefitsAdvertDescByJobId",
      "interviewFeedBack",
      "rejectInterviewCandidate",
      "fetchJobCandidateFrontSheet",
      "addJobCandidateFrontSheet",
      "getCandidateDetails",
    ]),
    notificationModalCallBack(action) {
      if (action === "Yes") {
        this.navToInterviewEvaluation();
      }
      this.notificationModal.isShowPopup = false;
    },
    checkIsExpired() {
      if (this.isExpired) {
        this.navToInterviewEvaluation();
      } else {
        this.notificationModal.isShowPopup = true;
      }
    },
    navToInterviewEvaluation() {
      const { job_id, candidate_uid, action_detail_id } = this.actionDetail;
      const query = getFilterQueryStringWithoutArray({ action_detail_id });
      // this.$router.push(
      //   `/interview-evaluation-form/job/${job_id}/candidate/${candidate_uid}/action_detail/${action_detail_id}`
      // );
      let routeInterviewEvaluation = `/interview-evaluation-form/job/${job_id}/candidate/${candidate_uid}/action_detail/${action_detail_id}`;
      window.open(routeInterviewEvaluation, "_blank");
      let payload = this.customerUserRoles.find(item => item.code === 2)
      if (payload) {      
        localStorage.setItem("current_user_type", JSON.stringify(payload));
        localStorage.setItem("active_tab", 0);
      }

    },
    handleChangeSelect(name, value) {
      Vue.set(this.interview, name, value);
      if (value?.label === "Approved") {
        this.interview.comments =
          "Interviewer already submitted evaluation form";
      }
    },
    navToJobDetails(job_id) {
      // let fullPath = this.$route.fullPath;s
      // this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      // this.$router.push({ path: `/job-list-preview/${job_id}` });
      this.$emit("openJobInfoModal", job_id);
    },
    navToActivityList() {
      this.$store.commit("SET_ACTIVE_TAB", 1);
      this.$router.push({ path: `/list-activity` });
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
      if (tab === 1) {
      this.showJobInfo = true;
      } else {
      this.showJobInfo = false;
  }
    },
    openJobAdvert(job_id) {
      this.fetchJobBenefitsAdvertDescByJobId({
        job_id,
        customer_id: this.getCustomerId,
      }).then((res) => {
        this.advertModal.isShowPopup = true;
      });
    },
    advertModalCallBack() {
      this.advertModal.isShowPopup = false;
    },
    navToCandidateDetails(candidate_uid) {
      // this.setCandidateListBackUrl(this.$route.fullPath);
      // this.$router.push({ path: `/candidate/${candidate_uid}` });
      this.$emit("openCandidateInfoModal", candidate_uid);
    },
    handleInput(name, value) {
      Vue.set(this.interview, name, value);
    },
    async onSubmit() {
      const isValid = await this.$refs.interviewForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const { job_id, action_detail_id, candidate_uid } = this.actionDetail;
      this.payload = {
        job_id,
        action_detail_id,
        candidate_uid,
        comments: this.interview?.comments,
      };
      if (this.interview?.action?.label === "Approved") {
        this.interviewFeedBack(this.payload);
      } else if (this.interview?.action?.label === "Disapproved") {
        this.rejectInterviewCandidate(this.payload);
      }
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    openMeet() {
      let routeData = `${this.interview_link}`;
      window.open(routeData, "_blank");
    },
    fileDownload(data) {
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      url = appendAccessToken(url);
      this.downloadDocument({ url, document_name });
    },
    preview(data) {
      // const _base_domain = "https://" + window.location.host;
      const link_to_document = generateFullUrlWithToken(data.link);
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data?.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data?.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data?.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    formatDate(date, pattern = "YYYY-MM-DD") {
      if (date) return m(date).format(pattern);
      return null;
    },
    openFrontSheet() {
      const { candidate, job } = this.actionDetail;
      const { job_id } = job;
      const { candidate_uid } = candidate;
      this.fetchJobCandidateFrontSheet({
        job_id,
        candidate_uid,
        order_by: "-job_candidate_front_sheet_id",
      }).then(async (res) => {
        if (res?.data) {
          await this.getCandidateDetails(candidate_uid);
          this.frontSheetModal.job = job;
          this.frontSheetModal.candidate = this.candidateProfile;
          this.frontSheetModal.frontSheetInfo = res?.data?.length
            ? res.data[0]
            : null;
          this.frontSheetModal.isShowPopup = true;
          this.frontSheetModal.comments = res?.data || [];
          this.$store.commit("SET_REVIEWED_CANDIDATEID", candidate_uid);
        } else {
          this.showToast({
            message: "Error while fetching Frontsheet",
            class: "bg-danger text-white",
          });
        }
      });
    },
    frontSheetModalCallBack(action, payload) {
      if (action && payload) {
        this.addJobCandidateFrontSheet(payload).then((res) => {
          this.frontSheetModal.isShowPopup = false;
        });
      } else {
        this.frontSheetModal.isShowPopup = false;
      }
    },
  },
  mounted() {
    const {
      candidate_uid,
      candidate: { customer_uid },
      action_detail_id,
    } = this.actionDetail;
    this.fetchDocumentByCandidateID({ candidate_uid, customer_uid });
  },
  filters: {
    phone(data) {
      if (data) return data.replace("^", "-");
      return "--";
    },
    convert2Sentence(data) {
      return _.startCase(data);
    },
    formatDate(data) {
      if (data) return m.utc(data).format("Do MMM YYYY");
      return "--";
    },
    formatTime(date) {
      if (date) {
        const dateObj = new Date();
        const dateSplit = date.split(":");
        dateObj.setHours(dateSplit[0], dateSplit[1], dateSplit[2]);
        return m(dateObj).format("hh:mm A");
      }
      return "--";
    },
    formatTime1(date) {
      if (date) return m(date).format("hh:mm A");
      return "--";
    },
    remainingDays(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const days = alertDate.diff(todaysdate, "days");
        return days >= 0
          ? `${days} Day${days > 1 ? "s" : ""}`
          : "Already Expired";
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  width: 110px;
}
.center {
  display: flex;
  justify-content: center;
}
.budget-class {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.1rem;
}
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
</style>
